.brown {
    color: $brown;
}

.dark-gold {
    color: $dark-gold;
}

.gold {
    color: $gold;
}


.white-bg {
    background: $white;
}
.apricot-bg {
    background: $apricot;
}
.gold-bg {
        background: $gold;
}
.dark-gold-bg {
        background: $dark-gold;
}