/*preloader*/
    #preloader {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9999999999999999;
    }
    #pre-logo{
        width: 240px;
        padding-bottom: 8vh;
        
        
        animation: preLogo 1.4s linear infinite alternate ;
    }

    @keyframes preLogo {
        from {opacity: 0.6  }
        to {opacity: 1; transform: scale(1.2)  }
    }