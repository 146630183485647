.clnd-footer {
    z-index: 2;
    padding: 2rem;
    border-top: $white 6px solid;
    border-right: $light-gray 6px solid;
    background: url(../img/bgrd.png) repeat center, $apricot;
    position: relative;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -4 12px 1px rgba(0, 0, 0, 0.24);
}

.footer-divider {
    margin-bottom: 1.2rem;
    min-height: 2.4rem;
    background: url(../img/divider-deco_2.svg) no-repeat center;
    background-size: contain;
}

.address {
    height: 100%;
    padding: 0 1rem;
    margin-bottom: 1.6rem;
    color: $cherry;
    border-left: 2px $cherry dotted;
    line-height: 1.2rem !important;
    i{
        margin-right: 0.4rem;
    }

}




.is-checked {
    background: $cherry;
}

.left-border {
    border-left: 1px $dark-gold solid;
}

hr {
    border-color: $dark-gold;
    margin: 0.6rem auto;
}