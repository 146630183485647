@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'footer';
@import 'preloader';
@import 'colours';


// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
//@include foundation-button-group;
@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
// @include foundation-callout;
@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.12rem;
}

.bold {
    font-weight: 700;

}
.s-bold {
    font-weight: 400;

}
.pre{
   white-space:pre-wrap;
}

.clnd-bg-full {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1000;
    -webkit-transform: translateZ(-10px);
    background: url(../img/bgrd.png) repeat center,
        linear-gradient(to bottom right, $clnd-1, $clnd-2);

}

.clnd-bg {
    background: url(../img/bgrd.png) repeat center,
        linear-gradient(to bottom right, $clnd-1, $clnd-2);

}

.clnd-white-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    background: linear-gradient(to bottom right, $w-light, $c-light);
}

.big-shadow{
    box-shadow: 0px 48px  64px 16px rgba($clnd-3, 0.48);
}

.clnd-header {
    padding-bottom: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.8vw;
    border-bottom: $medium-gray 6px solid;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.36);

}


.main-logo {
    padding: 3vh;

}

.tel{
    color: $dark-gold;
    line-height: 1.2rem;
    font-size: 1.4rem;
    font-weight: 400;
}
.viber{
    color: #7360f2;
        margin: 0.4rem;

}
.tlgrm{
    color: #3ca2d9;
        margin: 0.4rem;

}

.clnd-menu {
    font-size: 1.12rem;
    font-weight: 400;
    color: $white;
    padding: 1vh 1.8vh;
    margin: 1.2vh;
    border-radius: 6px;

    &:hover {
        background: $brown;
        cursor: pointer;
        transform: scale(108%);
        transition: all 0.4s;
    }

    &:active,
    &:visited {
        background: $cherry;
    }
}


.carton-hole {
    position: relative;
    border-top: $medium-gray 6px solid;
    border-left: $light-gray 6px solid;
    border-bottom: $white 6px solid;
    border-right: $light-gray 6px solid;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.36) inset;

    img {
        position: relative;
        z-index: -1;

    }
}

.carton {

    border-bottom: $medium-gray 5px solid;
    border-right: $light-gray 5px solid;
    border-top: $white 5px solid;
    border-left: $light-gray 5px solid;
    box-shadow: 3px 3px 5px -1px  rgba(0, 0, 0, 0.36);


}

.frame-card {

    img {
        transition: 0.6s all 0.2s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(2);
            transform-origin: top left;
        }

    }

}



.clnd-title {
    margin: 3vh 1rem;
    padding: 0.2rem 0 0;
    border-top: 2px $brown dotted;
    border-bottom: 2px $brown dotted;
}

.frame-descr {
    margin: 0.6rem 0 0.8rem;
    font-size: 0.84rem;

    span {
        font-size: 1.25rem;
    }

}

.price {
    border-left: 1px solid $dark-gold;
    padding: 0.4rem 0 0.6rem 0.6rem;
    line-height: 1rem;

    span {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.6rem;

    }
}

.cart-btn {
    color: $dark-gold;
    background: $apricot;

    span {
        font-size: 1.2rem !important;
        font-weight: 400 !important;
    }
}


.clnd-buy {
    color: $dark-gold;
    margin: 0.6rem 0 0.8rem;
    &:hover {
        background: url(../img/bgrd.png) repeat, $dark-gold;
        i {
            color: $apricot;
        }
    }

    &:focus {
        background: url(../img/bgrd.png) repeat, $gold;
        color: $dark-gold;
    }

    &:active {
        background: $cherry;
        color: $apricot;
    }

    span {
        font-size: 1.2rem !important;
        font-weight: 400 !important;
    }

    i {
        margin-top: -1rem;
        transform: translateY(0.3rem);
    }
}

.to-cart{
   color: $apricot;
    margin: 0.6rem 0 0.8rem;
    &:hover {
      color: $white;
        background: $cherry;
        
    }
}
.back-shop-btn, 
.sort-btn{
    color: $cherry !important;
    background: $gold !important;
    font-size: 1.1rem;
    border-radius: 6px;
}


#checkout {
    position: fixed;
    width: 24vh;
    height: 24vh;
    background: linear-gradient(to bottom left, rgba($clnd-3, 0.3) 24%, rgba($clnd-3, 0) 48%);

    top: 0;
    right: 0;
    z-index: 100;

    a {
        position: absolute;
        top: 1.6vh;
        right: 2vh;

    }

}

#frameDetails {
   width: 94vw; 
   margin-left: 2vw;
    
}

.prev-next {
    button {
        margin-bottom: 0;
    }
}

#frameOrder {
    background: $white;

    img {
        width: 120px;
    }
}

.empty-cart {
    font-size: 1.25rem;
    font-weight: 400;
    padding: 1rem 0;
}

.delete-item {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.form-sub {
    color: $dark-gold;
    font-size: 1.2rem;
    font-weight: 400;
}

.cart-order {
    position: relative;
    padding: 1rem 0 1.2rem;
    border-bottom: 1px solid $medium-gray;
}

#thankyou_message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($black, 0.48);
    z-index: 11000;
       .inner{
    padding: 1.2rem;

    }

}

#addedCartMsg {
    position: fixed;
    width: 100vw;
    z-index: 300;

    .grid-x {

        height: 100vh;
    }

    .cell {
        color: $white;
        background: $success;
        padding: 1.6rem;
    }

}

#cartView {

    background: $white;
    position: fixed;
    top: 10vh;
    right: 3vh;
    width: 280px;
    z-index: 120;
    box-shadow: 0px 0px 48px 0px rgba($clnd-2, 0.36);


}

// ngView Animation

.fade {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // height: 100%;
    opacity: 1;
}
.fade.ng-enter,
.fade.ng-leave {
    transition: all 0.4s ease;
}
.fade.ng-enter {
    opacity: 0;
}
.fade.ng-enter-active {
    opacity: 1;
}
.fade.ng-leave {
    opacity: 1;
}
.fade.ng-leave-active {
    opacity: 0;
}